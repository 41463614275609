@font-face {
  font-family: 'HMSans-Regular';
  src: local('HMSans-Regular'), url(./fonts/HMSans-Regular.woff2) format('woff2'),
    url(./fonts/HMSans-Regular.woff) format('woff');
}
@font-face {
  font-family: 'HMSans-SemiBold';
  src: local('HMSans-SemiBold'), url(./fonts/HMSans-SemiBold.woff2) format('woff2'),
    url(./fonts/HMSans-SemiBold.woff) format('woff');
}
@font-face {
  font-family: 'HMSans-Bold';
  src: local('HMSans-Bold'), url(./fonts/HMSans-Bold.woff2) format('woff2'),
    url(./fonts/HMSans-Bold.woff) format('woff');
}
@font-face {
  font-family: 'HMSerif-Regular';
  src: local('HMSerif-Regular'), url(./fonts/HMSerif-Regular.woff2) format('woff2'),
    url(./fonts/HMSerif-Regular.woff) format('woff');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.custom-apex-tooltip {
  top: 2% !important;
}
